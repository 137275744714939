.hero.bg-img {
  background: linear-gradient(
      rgba(33,154,147,0.9),
      rgba(33,154,147,0.6)
    ), url('../../assets/tom-ezzatkhah-103592-unsplash.jpg') no-repeat center;
    /* Photo by Tom Ezzatkhah on Unsplash */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

